@tailwind components;

@layer components {
  .btn {
    @apply inline-flex justify-center items-center border-[0.5px] font-medium cursor-pointer whitespace-nowrap shadow;
  }

  .btn-disabled {
    @apply opacity-60 cursor-not-allowed;
  }

  .btn-small {
    @apply px-2 h-6 rounded-md text-xs
  }

  .btn-medium {
    @apply px-3.5 h-8 rounded-lg text-[13px]
  }

  .btn-large {
    @apply px-4 h-9 rounded-[10px] text-sm
  }

  .btn-secondary {
    @apply bg-white hover:bg-white/80 border-gray-200 hover:border-gray-300 text-gray-700;
  }

  .btn-secondary-accent {
    @apply bg-white hover:bg-white/80 border-gray-200 hover:border-gray-300 text-primary-600;
  }

  .btn-primary {
    @apply bg-primary-600 hover:bg-primary-600 text-white;
  }

  .btn-warning {
    @apply bg-red-600 hover:bg-red-700 text-white;
  }

  .btn-ghost {
    @apply bg-transparent hover:bg-gray-200 border-transparent shadow-none text-gray-700;
  }
}
